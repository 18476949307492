.jobs-filters {
  margin-left: 20px;
}

.jobs-filters h2 {
  font-size: 1.8em;
}

.jobs-filters .card {
  margin-bottom: 10px;
}

.jobs-filters .card-title {
  text-transform: uppercase;
  font-size: 1.2em;
}

.jobs-filters {
  font-size: 0.8em;
}

.jobs-filters .checkbox label {
  color: #999;
}

.jobs-filters .checkbox label:hover {
  color: #2e2e2e;
}

.jobs-filters .checkbox input:focus + label {
  color: #2e2e2e;
}

.jobs-filters .checkbox-checked label {
  color: #2e2e2e;
}