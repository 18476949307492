#header {
  padding: 30px 0;
}

#header .navbar-brand-image {
  width: 177px;
}

#header .navbar-nav {
  font-family: 'Montserrat', Futura, 'Trebuchet MS', Arial, sans-serif;
}