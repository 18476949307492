.loading-state {
  position: relative;
  min-height: 400px;
  max-height: 100vh;
  width: 100%;
  text-align: center;
}

.loading-state-box {
  width: 100%;
  height: 8em;
  overflow: auto;
  margin: auto;
  position: absolute;
  top: 0; left: 0; bottom: 0; right: 0;
}

.loading-state-text {
  margin-top: 20px;
  font-size: 1.4em;
}