.ex-sidebar {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  background-color: #ddd;
  background-clip: border-box;
  padding: 10px;
  margin-top: -20px;
  z-index: 1000;
}

.ex-sidebar-head h2 {
  font-family: Verdana, "Helvetica Neue", Arial, Helvetica, Geneva, sans-serif;
  font-size: 1em !important;
  text-transform: none !important;
  margin-bottom: 0;
}

.ex-sidebar-space {
  height: 2em;
}

.ex-sidebar-head h2 {
  display: inline-block;
}

.ex-sidebar-head:after {
  display: inline-block;
  margin-left: .255em;
  vertical-align: .255em;
  content: "";
  border-top: .3em solid;
  border-right: .3em solid transparent;
  border-bottom: 0;
  border-left: .3em solid transparent;
  position: absolute;
  right: 10px;
  top: 17px;
}

.ex-sidebar-body {
  padding-top: 10px;
}

@media (min-width: 768px) {
  .ex-sidebar {
    position: relative;
    width: 100%;
    background-color: transparent;
    padding: 0 10px ;
    margin-top: auto;
    color: #212529;
  }

  .ex-sidebar-head h2 {
    font-family: 'Montserrat', Futura, 'Trebuchet MS', Arial, sans-serif;
    font-size: auto !important;
    text-transform: uppercase !important;
  }

  .ex-sidebar-head:after {
    display: none;
  }

  .ex-sidebar-body {
    display: block !important;
  }

  .ex-sidebar-space {
    display: none;
  }
}