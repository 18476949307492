.date-input-group .form-control {
  padding: 0 !important;
}

.date-input-group .form-control input {
  height: 100%;
  width: 100%;
  margin: 0;
  border: none;
  border-radius: 0 3px 3px 0;
}

.was-validated .form-date-group .invalid-feedback {
  display: block !important;
}

.date-picker-overlay {
  z-index: 1000;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}