.manage-job {
  margin-bottom: 50px;
}

.manage-job h3 {
  text-transform: uppercase;
  font-size: 1em;
  margin: 30px 0 10px 0;
}

.manage-job .card-filled {
  padding: 0;
}

.manage-job .card-filled .card-padding {
  padding-top: 10px;
  padding-bottom: 10px;
}

.manage-job .card-filled .filled-block {
  background-color: #e9ecef;
}

.manage-job .card-filled .filled-block-right {
  position: relative;
  border-radius: 0 0 3px 3px;
}

.manage-job .card-filled .text-editor {
  margin: 0;
}

.manage-job .card-filled .text-editor-box {
  border: none;
}

.manage-job .card-filled .text-editor textarea {
  padding: 15px 30px;
}

.manage-job .card-filled .text-editor-footer {
  padding: 15px;
  background-color: #e9ecef;
  border: none;
  font-size: 0.8em;
  color: #6c757d;
}

.tip-title {
  font-size: .8em;
  color: #6c757d !important;
}

.tip-body {
  font-size: .8em;
  color: #6c757d !important;
}

.DayPickerInput input {
  display: inline-block;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0;
  -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}


.preview-content {
  text-align: center;
  margin: 15px 30px;
}

@media (min-width: 992px) {
  .manage-job .card-filled .filled-block-right {
    border-radius: 0 3px 3px 0;
    height: auto;
    position: absolute;
    left: auto;
    top: 0;
    right: 0;
    bottom: 0;
  }

  .preview-content {
    text-align: center;
    margin: 30px;
  }
}

.form-control-nested {
  padding: 0;
}

.form-control-nested input {
  margin: 0;
  border: none;
  width: 100%;
}

.manage-job .form-toolbar {
  background-color: #fff;
  padding: 10px 0;
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.manage-job .form-alert {
  flex-grow: 1;
}

.manage-job .form-alert .error-message .alert {
  padding: 0.375rem 0.75rem;
  margin: 0;
}

.manage-job .form-alert .error-message .close {
  padding: 0 0.75rem;
}

.manage-job .form-buttons {
  white-space: nowrap;
  text-align: right;
}

.manage-job .form-buttons .btn {
  margin-left: 8px;
}