.rc-time-picker input {
  display: inline-block;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0;
  -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.rc-time-picker-panel-inner, .rc-time-picker-panel-select {
  font-size: 1rem;
  line-height: 1.5;
}

.rc-time-picker-panel-inner {
  top: 2px;
  left: 3px;
}

.rc-time-picker-panel-select li {
  height: auto;
  padding: 0.375rem 0.75rem;
}

.time-input-group .form-control {
  padding: 0
}

.time-input-group .form-control input {
  height: 100%;
  width: 100%;
  margin: 0;
  border: none;
  border-radius: 0 3px 3px 0;
}

.time-input .rc-time-picker-clear {
  display: none;
}