.checkbox {
  position: relative;
  margin-top: 10px;
}

.checkbox-input {
  position: absolute;
  margin: 0;
  opacity: 0;
}

.checkbox-label {
  margin: 0;
}

.checkbox-input + label:before {
  content: '';
  margin-right: 10px;
  display: inline-block;
  vertical-align: middle;
  width: 15px;
  height: 15px;
  border-radius: 2px;
  background: white;
  border: 1px solid #ccc;
}

.checkbox-input:hover + label:before {
  background: #166BA0;
  border: 1px solid #166BA0;
}

.checkbox-input:focus + label:before {
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
  border-color: #66afe9;
  outline: 0;
}

.checkbox-input:checked + label:before {
  background: #166BA0;
  border: 1px solid #166BA0;
}

.checkbox-input:disabled + label:before {
  box-shadow: none;
  background: #ddd;
}

.checkbox-input:checked + label:after {
  content: '';
  position: absolute;
  left: 2px;
  top: 50%;
  background: white;
  width: 2px;
  height: 2px;
  box-shadow:
    2px 0 0 white,
    4px 0 0 white,
    4px -2px 0 white,
    4px -4px 0 white,
    4px -6px 0 white,
    4px -8px 0 white;
  transform: rotate(45deg);
}