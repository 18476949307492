.job-header {
  border-bottom: 1px solid rgba(0,0,0,.1);
  padding-bottom: 10px;
  margin-bottom: 20px;
}

.job-sidebar-button-area {
  margin-bottom: 20px;
}

.job-application-buttons .btn {
  margin-right: 8px;
}

.job-application-buttons .btn.btn-block {
  margin-right: 0;
}