.form-toolbar {
  background-color: #fff;
  padding: 10px 0;
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.form-toolbar .form-alert {
  flex-grow: 1;
}

.form-toolbar .form-alert .error-message .alert {
  padding: 0.375rem 0.75rem;
  margin: 0;
}

.form-toolbar .form-alert .error-message .close {
  padding: 0 0.75rem;
}

.form-toolbar .form-buttons {
  white-space: nowrap;
  text-align: right;
}

.form-toolbar .form-buttons .btn {
  margin-left: 8px;
}