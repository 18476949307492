.job-listing {
  padding: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.job-listing .job-details .btn-link {
  padding: 0;
  border: none;
}

.job-listing .job-remote-badge {
  margin-left: 2px;
  font-size: 1em;
}

.job-listing .job-description {
  font-size: 0.8em;
  margin: 10px 0;
}
