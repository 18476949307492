body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4 {
  font-family: 'Montserrat', Futura, 'Trebuchet MS', Arial, sans-serif;
}

.form-control.is-invalid, .was-validated .form-control:invalid {
  background-image: none;
}

.form-control.is-valid, .was-validated .form-control:valid {
  border: 1px solid #ced4da;
  background-image: none;
}