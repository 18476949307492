.text-editor {
  width: 100%
}

.text-editor-box {
  width: 100%;
  border: 1px solid #ced4da;;
  border-radius: .25rem;
}

.text-editor-box textarea {
  width: 100%;
  min-height: 300px;
  border: none;
  border-radius: .25rem .25rem 0 0;
  padding: .375rem .75rem;
  vertical-align: top;
}

.text-editor-box .text-editor-footer {
  border-top: 1px solid #ced4da;
  font-size: 0.7em;
  padding: 5px;
}


