.error-state {
  position: relative;
  height: 100%;
  min-height: 8em;
  max-height: 600px;
  width: 100%;
  text-align: center;
}

.error-state-box {
  width: 100%;
  height: 8em;
  overflow: auto;
  margin: auto;
  position: absolute;
  top: 0; left: 0; bottom: 0; right: 0;
}

.error-state h1 {
  font-family: monospace;
}

.error-state h2 {
  font-size: 1.2em;
}

.error-state p {
  font-size: .8em;
}