.footer {
  background-color: #ddd;
  width: 100%;
  padding: 20px 0 5px 0;
}

.footer-container {
  padding: 20px;
}

.footer h2 {
  font-size: 1.3em;
}

.footer-nav .nav-item {
  line-height: 1em;
}