.manage-job-listing {
  padding: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.badge-status {
  margin-right: 8px;
  font-size: 1em;
}

.job-location, .job-type-text, .job-details-divider{
  font-size: 0.8em;
}

.job-details-block {
  width: 100%
}

.job-controls-block {
  margin: 8px 0;
  width: 100%;
}

.manage-job-dropdown .manage-job-btn-edit {
  flex-grow: 1 !important;
}

.manage-job-dropdown .manage-job-btn-carot {
  flex-grow: 0 !important;
}

@media (min-width: 768px) {
  .job-details-block {
    width: auto;
    flex-grow: 1;
  }

  .job-controls-block {
    margin: 8px;
    width: auto;
  }

  .job-controls-block .btn {
    margin-left: 6px;
  }
}