.empty-state {
  max-height: 100vh;
  min-height: 400px;
  width: 100%;
  text-align: center;
}

.empty-state-box {
  width: 80%;
  max-width: 400px;
  margin: 30px auto;
}

.empty-state-icon {
  width: 100%;
  max-width: 200px;
  margin-bottom: 40px;
}

.empty-state h2 {
  font-size: 1.2em;
}

.empty-state p {
  font-size: .8em;
}